exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-freelance-jim-taylor-js": () => import("./../../../src/pages/freelance/jim-taylor.js" /* webpackChunkName: "component---src-pages-freelance-jim-taylor-js" */),
  "component---src-pages-freelance-js": () => import("./../../../src/pages/freelance.js" /* webpackChunkName: "component---src-pages-freelance-js" */),
  "component---src-pages-freelance-st-marks-place-js": () => import("./../../../src/pages/freelance/st-marks-place.js" /* webpackChunkName: "component---src-pages-freelance-st-marks-place-js" */),
  "component---src-pages-freelance-storiicare-js": () => import("./../../../src/pages/freelance/storiicare.js" /* webpackChunkName: "component---src-pages-freelance-storiicare-js" */),
  "component---src-pages-freelance-the-pass-js": () => import("./../../../src/pages/freelance/the-pass.js" /* webpackChunkName: "component---src-pages-freelance-the-pass-js" */),
  "component---src-pages-freelance-zactly-js": () => import("./../../../src/pages/freelance/zactly.js" /* webpackChunkName: "component---src-pages-freelance-zactly-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */)
}

